import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

import { routes } from "@/custom_config.js";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const user = localStorage.getItem("userConfInfo");

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (user == null) {
            next({
                path: "/login",
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
