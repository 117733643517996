import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import axios from "axios";
/* Base url for the api */
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
//axios.defaults.baseURL = "http://192.168.11.129/firegui_crm/";

/* vue3-signature */
//import Vue3Signature from "vue3-signature";

//import firebaseMessaging from "./firebase";
// Import the functions you need from the SDKs you need
/* import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyAymguREhNMAMw917C0qnGRYAkwPT2o8Fo",
    authDomain: "apph2-2e295.firebaseapp.com",
    projectId: "apph2-2e295",
    storageBucket: "apph2-2e295.appspot.com",
    messagingSenderId: "613154686569",
    appId: "1:613154686569:web:5232cd5406bac91e7884f8",
}; */

//import { firebaseConfig, vapidKey } from './config';

/**
 *
 * ! PWA Service Worker
 * */
import "./registerServiceWorker";

/**
 *
 * ! PWA Elements for Camera
 *
 */
import { defineCustomElements } from "@ionic/pwa-elements/loader";

/**
 *
 * ! Import localstorage global key
 *
 */
import { LOCALSTORAGE_KEY } from "@/custom_config";

/* if (localStorage.getItem(`${LOCALSTORAGE_KEY}`) != null && localStorage.getItem("ricordami") != null) {
    console.log(localStorage.getItem("ricordami"));
    router.replace("/tab1");
} */

const app = createApp(App)
    .use(IonicVue)
    //.use(Vue3Signature)
    .use(router);

app.provide("LOCALSTORAGE_KEY", LOCALSTORAGE_KEY);

//initializeApp(firebaseConfig);

app.config.globalProperties.$showPresenze = process.env.VUE_APP_SHOW_PRESENZE;
app.config.globalProperties.$showButtonsTimbrature = process.env.VUE_APP_SHOW_BUTTONS_TIMBRATURE;
app.config.globalProperties.$showButtonPresenza = process.env.VUE_APP_SHOW_BUTTON_PRESENZA;
app.config.globalProperties.$showQrCodeButton = process.env.VUE_APP_SHOW_QRCODE_BUTTON;
app.config.globalProperties.$showStraordinarioButton = process.env.VUE_APP_SHOW_STRAORDINARIO_BUTTON;
app.config.globalProperties.$showDocumenti = process.env.VUE_APP_SHOW_DOCUMENTI;
app.config.globalProperties.$showRichieste = process.env.VUE_APP_SHOW_RICHIESTE;
app.config.globalProperties.$showBancaOre = process.env.VUE_APP_SHOW_BANCA_ORE;
app.config.globalProperties.$showAutomezzi = process.env.VUE_APP_SHOW_AUTOMEZZI;
app.config.globalProperties.$showNoteSpesa = process.env.VUE_APP_SHOW_NOTE_SPESA;
app.config.globalProperties.$showRimborsi = process.env.VUE_APP_SHOW_RIMBORSI;
app.config.globalProperties.$showInterventi = process.env.VUE_APP_SHOW_INTERVENTI;
app.config.globalProperties.$showTickets = process.env.VUE_APP_SHOW_TICKETS;
app.config.globalProperties.$showTimesheet = process.env.VUE_APP_SHOW_TIMESHEET;
app.config.globalProperties.$showTasks = process.env.VUE_APP_SHOW_TASKS;
app.config.globalProperties.$showTimesheetDateHour = process.env.VUE_APP_SHOW_TIMESHEET_DATE_HOUR;
app.config.globalProperties.$showClienti = process.env.VUE_APP_SHOW_CLIENTI;
app.config.globalProperties.$showProgetti = process.env.VUE_APP_SHOW_PROGETTI;
app.config.globalProperties.$showLeads = process.env.VUE_APP_SHOW_LEADS;
app.config.globalProperties.$showBacheca = process.env.VUE_APP_SHOW_BACHECA;
app.config.globalProperties.$showProgrammaLavoro = process.env.VUE_APP_SHOW_PROGRAMMA_LAVORO;
app.config.globalProperties.showOrdiniInterni = process.env.VUE_APP_SHOW_ORDINI_INTERNI;
app.config.globalProperties.$showSondaggi = process.env.VUE_APP_SHOW_SONDAGGI;
app.config.globalProperties.$showListe = process.env.VUE_APP_SHOW_LISTE;
app.config.globalProperties.$showMagazzino = process.env.VUE_APP_SHOW_MAGAZZINO;
app.config.globalProperties.$showCalendario = process.env.VUE_APP_SHOW_CALENDARIO;
app.config.globalProperties.$showProfilo = process.env.VUE_APP_SHOW_PROFILO;
app.config.globalProperties.$LOCALSTORAGEKEY = LOCALSTORAGE_KEY;
// Firebase
//app.config.globalProperties.$messaging = firebaseMessaging;
//console.log(app.config.globalProperties.$messaging);

router.isReady().then(() => {
    app.mount("#app");
    defineCustomElements(window);
});
