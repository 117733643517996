import {
    documents,
    time,
    person,
    clipboard,
    car,
    copy,
    ticket,
    wallet,
    card,
    people,
    book,
    informationCircle,
    documentText,
    listCircle,
    hourglass,
    newspaper,
    calendar,
    cube,
    home,
    list,
    barcode,
    shareSocial,
} from "ionicons/icons";

import Login from "@/views/Login.vue";

//Chiave localsotrage
const LOCALSTORAGE_KEY = `user${process.env.VUE_APP_LOCALSTORAGE_KEY}`;

// Pagina da vedere dopo il login
const pageAfterLogin = "presenze";

// Pulsanti presenti nella dashboard
const dashbordButtons = {
    presenze: true,
    documenti: true,
    richieste: true,
    automezzi: true,
    spese: true,
    tickets: true,
};

// Configurazione del menu
const config_menu = [
    {
        show: true,
        route: "presenze",
        icon: time,
        name: "Presenze",
    },
    {
        show: true,
        route: "documenti",
        icon: documents,
        name: "Documenti",
    },
    {
        show: true,
        route: "richieste",
        icon: clipboard,
        name: "Richieste",
    },
    {
        show: true,
        route: "banca",
        icon: hourglass,
        name: "Banca ore",
    },
    {
        show: false,
        route: "automezzi",
        icon: car,
        name: "Automezzi",
    },
    {
        show: true,
        route: "spese",
        icon: wallet,
        name: "Note spesa",
    },
    {
        show: false,
        route: "calendario",
        icon: calendar,
        name: "Calendario",
    },
    {
        show: false,
        route: "rimborsi",
        icon: card,
        name: "Rimborsi km",
    },
    {
        show: false,
        route: "interventi",
        icon: documentText,
        name: "Interventi",
    },
    {
        show: false,
        route: "tickets",
        icon: ticket,
        name: "Tickets",
    },
    {
        show: false,
        route: "task",
        icon: home,
        name: "Tasks",
    },
    {
        show: false,
        route: "timesheet",
        icon: time,
        name: "Timesheet",
    },
    {
        show: false,
        route: "clienti",
        icon: people,
        name: "Anagrafiche",
    },
    {
        show: false,
        route: "progetti",
        icon: listCircle,
        name: "Progetti",
    },
    {
        show: false,
        route: "leads",
        icon: book,
        name: "Leads",
    },
    {
        show: false,
        route: "bacheca",
        icon: shareSocial,
        name: "Bacheca",
    },
    {
        show: false,
        route: "programma",
        icon: calendar,
        name: "Programma lavoro",
    },
    {
        show: false,
        route: "ordini",
        icon: cube,
        name: "Ordini interni",
    },
    {
        show: false,
        route: "sondaggi",
        icon: newspaper,
        name: "Sondaggi",
    },
    {
        show: false,
        route: "magazzino",
        icon: barcode,
        name: "Magazzino",
    },
    {
        show: false,
        route: "todo",
        icon: list,
        name: "Liste",
    },
    {
        show: true,
        route: "profilo",
        icon: person,
        name: "Profilo",
    },
];

// ROUTE CUSTOM
const routes = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        component: Login,
        beforeEnter: (to, from, next) => {
            const user = localStorage.getItem("userConfInfo");
            const ricordami = localStorage.getItem("ricordami");
            if (user && ricordami) {
                next({ path: `/${pageAfterLogin}` });
            } else {
                next();
            }
        },
    },
    {
        path: "",
        redirect: `/${pageAfterLogin}`,
    },
    {
        //Presenze
        path: "/presenze",
        name: "Presenze",
        component: () => import("@/views/Presenze.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Documenti
        path: "/documenti",
        name: "Documenti",
        component: () => import("@/views/Documenti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Richieste
        path: "/richieste",
        name: "Richieste",
        component: () => import("@/views/Richieste.vue"),
        meta: { requiresAuth: true },
    },
    {
        //BancaOre
        path: "/banca",
        name: "BancaOre",
        component: () => import("@/views/BancaOre.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Automezzi
        path: "/automezzi",
        name: "Automezzi",
        component: () => import("@/views/Automezzi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Nota Spese
        path: "/spese",
        name: "NotaSpese",
        component: () => import("@/views/NotaSpese.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Rimborsi km
        path: "/rimborsi",
        name: "Rimborsi",
        component: () => import("@/views/Rimborsi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Interventi
        path: "/interventi",
        name: "Interventi",
        component: () => import("@/views/Interventi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Tickets
        path: "/tickets",
        name: "Tickets",
        component: () => import("@/views/Tickets.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Timesheet
        path: "/timesheet",
        name: "Timesheet",
        component: () => import("@/views/Timesheet.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Clienti
        path: "/clienti",
        name: "Clienti",
        component: () => import("@/views/Clienti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Progetti
        path: "/progetti",
        name: "Progetti",
        component: () => import("@/views/Progetti.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Leads
        path: "/leads",
        name: "Leads",
        component: () => import("@/views/Leads.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Sondaggi
        path: "/sondaggi",
        name: "Sondaggi",
        component: () => import("@/views/Sondaggi.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Confindustria
        path: "/conf",
        name: "Confindustria",
        component: () => import("@/views/Confindustria.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Programma Lavoro
        path: "/programma",
        name: "ProgrammaLavoro",
        component: () => import("@/views/ProgrammaLavoro.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Ordini
        path: "/ordini",
        name: "OrdiniInterni",
        component: () => import("@/views/OrdiniInterni.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Task
        path: "/task",
        name: "Task",
        component: () => import("@/views/Task.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Cambio password
        path: "/changePassword",
        component: () => import("@/views/ChangePassword.vue"),
        meta: { requiresAuth: true },
    },
    {
        //simulatore
        path: "/simulatore",
        name: "Simulatore",
        component: () => import("@/views/Simulatore.vue"),
        meta: { requiresAuth: true },
    },
    {
        //Profilo
        path: "/profilo",
        name: "Profilo",
        component: () => import("@/views/Profilo.vue"),
        meta: { requiresAuth: true },
    },
    // altre rotte definite qui
    {
        path: "/:catchAll(.*)", // Catch-all route
        redirect: { name: "Presenze" }, // Usa il nome della rotta
    },
];

export { LOCALSTORAGE_KEY, pageAfterLogin, dashbordButtons, config_menu, routes };
